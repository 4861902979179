import { render, staticRenderFns } from "./ImportWarranty.vue?vue&type=template&id=3126aab0&scoped=true&"
import script from "./ImportWarranty.vue?vue&type=script&lang=js&"
export * from "./ImportWarranty.vue?vue&type=script&lang=js&"
import style0 from "./ImportWarranty.vue?vue&type=style&index=0&id=3126aab0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3126aab0",
  null
  
)

export default component.exports